import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { ProjectCard } from "../components/ProjectCard";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Projects`}</h1>
    <h3>{`Things that I have made`}</h3>
    <SourceList filter="projects" mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.map((edge, index) => {
          const {
            frontmatter: {
              title,
              misc,
              date,
              featuredImageUrl
            },
            fields: {
              slug
            }
          } = edge.node;
          return <ProjectCard index={index} cardImage={featuredImageUrl} title={title} misc={misc} date={date} slug={slug} mdxType="ProjectCard" />;
        })}
    </Flex>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      